.residential-card{
    background-color: white;
    height: 21vw;
    width: 18vw;
    min-height: 21vw;
    min-width: 18vw;
    max-height: 21vw;
    max-width: 18vw;
    border-radius: 1.5vw;
    box-Shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) ;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0.625vw 0.625vw;
}

.residential-card-title{
    display: flex;
    justify-content: center;
    height: 1.85vw;
}

.residential-card-title h1{
    font-size: 1vw;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}


.residential-card-location{
    display: flex;
    justify-content: start;
    padding-top: 0.5vw;
}
.residential-card-location h2{
    font-size: 0.9vw;
    font-weight: 600;
    max-width: 16.042vw;
}


.residential-card-budget{
    display: flex;
    justify-content: start;
    padding-top: 0.5vw;
}
.residential-card-budget h2{
    font-size: 0.9vw;
    font-weight: 600;
    max-width: 16.042vw;
}


.residential-card-type{
    display: flex;
    justify-content: start;
    padding-top: 0.5vw;
}
.residential-card-type h2{
    font-size: 0.9vw;
    font-weight: 600;
    max-width: 16.042vw;
}

.residential-card-bhk{
    display: flex;
    justify-content: start;
    padding-top: 0.5vw;
}
.residential-card-bhk h2{
    font-size: 0.9vw;
    font-weight: 600;
    max-width: 16.042vw;
}

.residential-card-des{
    display: flex;
    justify-content: start;
    padding-top: 0.5vw;
}
.residential-card-des h2{ 
    font-weight: 600;
    max-width: 16.042vw;
    font-weight: 500;
    font-size: 0.625vw;
    max-height: 7.5vw;
    overflow: hidden;
    text-overflow: ellipsis;
}

.residential-card-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
}

.residential-card-btn .btn{
    background-color: #E73437;
    font-family: 'Poppins', sans-serif;
    font-size: 0.729vw;
    color: white;
    font-weight: 800;
    border: none;
    width: 6.25vw;
    height: 2.083vw;
    border-radius: 0.26vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.capitalize {
    text-transform: capitalize;
  }


@media (max-width:600px){
    .residential-card{
        width: 80vw;
        min-width: 80vw;
        height: 102.993vw;
        min-height: 102.993vw;
        border-radius: 4vw;
        padding: 3.2vw 3.2vw;
    }

    .residential-card-title{
        height: 7vw;
    }
    .residential-card-title h1{
        font-size: 4.2vw;
        font-weight: 700;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .residential-card-location{
        padding-top: 4.2vw;
    }
    .residential-card-location h2{
        font-size: 3.733vw;
        font-weight: 600;
        max-width: 75vw;
    }

    .residential-card-budget{
        padding-top: 2vw;
    }
    .residential-card-budget h2{
        font-size: 3.733vw;
        font-weight: 600;
        max-width: 75vw;
    }

    .residential-card-type{
        padding-top: 2vw;
    }
    .residential-card-type h2{
        font-size: 3.733vw;
        font-weight: 600;
        max-width: 75vw;
    }

    .residential-card-bhk{
        padding-top: 2vw;
    }
    .residential-card-bhk h2{
        font-size: 3.733vw;
        font-weight: 600;
        max-width: 75vw;
    }

    .residential-card-des{
        padding-top: 2vw;
    }
    .residential-card-des h2{
        font-size: 3.2vw;
        font-weight: 500;
        max-width: 80vw;
        max-height: 38.4vw;
    }

    .residential-card-btn{
        padding: 3vw;
    }
    .residential-card-btn .btn{
        font-size: 3.2vw;
        font-weight: 700;
        border: none;
        width: 26.667vw;
        height: 8.553vw;
        border-radius: 1.5vw;
        text-align: center;
    }
}
