.dispCards{
    display: flex;
    flex-direction: row;
    width: 78vw;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 1.5vw;
    gap:1.5vw;
}

@media (max-width:600px){
    .dispCards{
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
        box-sizing: border-box;
        margin-top: 1.5vw;
        gap:5vw;
        padding-bottom: 10vw;
    }
}