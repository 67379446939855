.whatsappBox button h2{
    font-size: 1.25vw;
    font-weight: 600;
    color: black;
}

.whatsappBox .whatsappLogo{
    width: 2.5vw;
}

.whatsappBox .colorChange{
    color: #25395A;
}

.whatsappBox button{
    background-color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.75vw;
    width: 17vw;
    border: 0.12vw solid black;
    border-radius: 0.5vw;
}