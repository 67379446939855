.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 5vh;
    background-color: #25395A ;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: row;
    gap: 1vw;
}
.footer-left{
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
}
.footer-right{
    display: flex;
    flex-direction: row;
    gap: 1vw;
}

.footer .text, .number{
    font-size: 0.833vw;
    font-weight: 500;
    color: white;
    margin: 0.4vw;
}

a{
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 0.833vw;
    
}

@media (max-width:600px){

    .footer{
        flex-direction: column;
        height: 8vh;
    }

    .footer .text, .number{
        font-size: 2.5vw;
        font-weight: 500;
        color: white;
        margin: 1vw;
    }
    a{
        font-size: 2.5vw;
        font-weight: 500;
    }
    .footer-right{
        gap: 4vw;
    }
}