*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body{
  
  display: flex;
  justify-content: center;
}