.commercial{
    display: flex;
    flex-direction: column;
}

.commercial .searchBarDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5vw;
}

.commercial .titleDiv{
    display: flex;
    justify-content: center; 
    width: 100%;
    height: 3vw;  
}

.commercial .titleDiv h1{
    font-size: 2.083vw;
    font-family: 700;
}

.commercial .outputDiv{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}


.extraDiv{
    width: 100%;
    height: 10vw;
}

@media (max-width:600px){
    .commercial .searchBarDiv{
        margin-top: 8vw;
    }
    .commercial .titleDiv{
        margin-top: 8vw;
    }
    .commercial .titleDiv h1{
        font-size: 5.333vw;
        font-family: 700;
    }
    .commercial .outputDiv{
        margin-top: 10vw;
    }

    .extraSpace{
        background-color: rgb(255, 255, 255);
        height: 20vw;
        width: 100vw;
    } 
}