.filter-body{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
}

.filter-body label{
  font-size: 1vw;
}


.filter-title h1{
    font-size: 1.875vw;
    font-weight: 600;
    text-align: center;
    padding-top: 0.625vw;
    padding-bottom: 0.8vw;
}


.filter-location{
    padding-bottom: 0.625vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter-location input{
    background-color: white;
    border: 0.15vw solid #E73437;
    border-radius: 0.4vw;
    width: 80vw;
    height: 3.333vw;
    color: rgb(0, 0, 0);
    font-size: 1.25vw;
    font-weight: 300;
    box-sizing: border-box;
    padding: 1vw 1vw;
    font-family:'Poppins', sans-serif;
    
}

  
.filter-location input::placeholder {
    color: #E73437;
    text-align: center;
  }

.filter-category{
    display: flex;
    box-sizing: border-box;
    padding-left: 10vw;
    margin-top: 1vw;
    margin-bottom: 2vw;
}
.filter-body .text h2{
    font-size: 1.25vw;
    font-weight: 500;
}
.filter-body .text{
    width: 15.625vw;
    display: flex;
    align-items: center;
    justify-content: start;
}

.filter-category .radio-container{
  display: flex;
  flex-wrap: wrap;
  width: 25vw;
  gap:0.8vw;
}

.filter-body .filter-type{
  margin-top: 2.5vw;
}
.filter-type .radio-container{
  display: flex;
  flex-wrap: wrap;
  width: 35vw;
  gap: 0.8vw;
}
  
  .radio-label {
    display: flex;
    align-items: center;
    margin-bottom: 0.26vw;
  }
  
  .radio-input {
    display: none;
  }
  
  .radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.542vw;
    height: 2.708vw;
    background-color: white;
    color: #E73437;
    border: 0.15vw solid #E73437;
    border-radius: 0.3vw;
    cursor: pointer;
    margin-right: 1.25vw;
    font-weight: 400;
  }
  
  .radio-button.checked {
    background-color: #E73437;
    color: white;
  }
  
  .radio-button:hover {
    background-color: #E73437;
    color: white;
  }
  


.filter-type{
    display: flex;
    box-sizing: border-box;
    padding-left: 10vw;
    margin-top: 1vw;
    margin-bottom: 2.083vw;
}

.filter-price{
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  margin-top: 5vw;
  /* height: 10.208vw;   */
}


.filter-price .label{
  display: flex;
  flex-direction: row;
  width: 85%;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 3vw;
}
.filter-price .sliderContainer{
  width: 85%;
}

.filter-price .label h2{
  font-size: 1vmax;
  font-weight: 600;
  color: #E73437;
}

.slider{
  width: 100%;
  height: 0.208vw;
  background-color:#E73437;
  display: flex;
  
}

.slider .thumb{
  width: 1vw;
  height: 1vw;
  background-color: white;
  border-radius: 100%;
  border: 0.208vw solid #E73437;
  top : -0.5vw;
}




.filter-btn{
    display: flex;
    justify-content: center;
}
.filter-btn input{
    background-color: #E73437;
    color: white;
    width: 7.969vw;
    height: 2.708vw;
    border: none;
    border-radius: 0.3vw;
    font-size: 1.15vw;
    font-weight: 600;
    font-family:'Poppins', sans-serif;
}

.extra{
  height: 3vw;
}

@media (max-width:600px){

  .filter-title h1{
    font-size: 5.333vw;
    padding-top: 6.4vw;
    padding-bottom: 3.2vw;
  }

  .filter-location input{
    border: 0.3vw solid #E73437;
    border-radius: 1.3vw;
    width: 88vw;
    height: 10.667vw;
    color: rgb(0, 0, 0);
    font-size: 3.5vw;
    padding: 1vw 2vw;
  }

  .filter-category{
    padding-left: 7vw;
    margin-top: 5vw;
    margin-bottom: 2vw;
  }
  .filter-body .text{
      width:33vw;
  }
  .filter-body .text h2{
    font-size: 3.733vw;
  }
  
  .filter-category .radio-container {
    width: 70vw;
    box-sizing: border-box;

  }
  .filter-type .radio-container {
    width: 70vw;
    box-sizing: border-box;
    padding-left: 2vw;
    margin-top: 4vw;
  }
  .filter-body label{
    font-size: 3.5vw;
  }
  .radio-button {
    width: 25vw;
    height: 11vw;
    border: 0.25vw solid #E73437;
    border-radius: 0.9vw;
    margin-right: 1.25vw;
    padding : 0vw 1.5vw;
  }
  .filter-type{
    padding-left: 7vw;
    margin-top: 2vw;
  }
  .filter-type .text{
    width: 30vw;
  }

  .slider{
    height: 0.8vw;
    width: 100%; 
  }
  .slider .thumb{
    width: 3vw;
    height: 3vw;
    background-color: white;
    border-radius: 100%;
    border: 0.5vw solid #E73437;
    top : -1.5vw;
  }
  .filter-price{
    margin-top: 10vw;
  }
  .filter-price .sliderContainer{
    width: 90%;
  }
  .filter-price .label{
    margin-top: 10vw;
    width: 90vw;
    display: flex;
  }
  .filter-price .label h2{
    font-size: 3.5vw;
    display: flex;
    flex-direction: row;
    width: 20vw;
  }
  .filter-btn input{
    width: 25vw;
    height: 10vw;
    border-radius: 1vw;
    font-size: 3.5vw;
    margin-top: 5vw;
  }

  .extra{
    height: 12vh;
    width: 100%;
  }
}