 .navbar{
    width: 100%;
    height: 8vh;
    background-color: #F6F6F6 ;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
 }


 .navbar .logoContainer{
    width: 33.33vw;
    display: flex;
    justify-content: start;
    align-items: center;
 }
 .navbar .logoContainer img{
    width: 6.146vw;
    margin-left: 0.833vw;
 }


 .navbar .textContainer{
    width: 33.33vw;
    display: flex;
    align-items: center;
    justify-content: center;
 }
 .navbar .textContainer .link{
    font-size: 1.875vw;
    color: #E73437;
    font-weight: 700;
    position: absolute;
    text-decoration: none;
 }


 .navbar .menuContainer{
    width: 33.33vw;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: right;
 }
 .navbar .menuContainer button{
    border:none;
    width: 2.5vw;
    height: 3.6vh;
    position:absolute;
    right: 0.833vw;
    background-color: #F6F6F6;
 }
 .navbar .menuContainer img{
    border:none;
    width: 2.5vw;
    height: 3.6vh;
    cursor: pointer;
 }

 .flBtn{
   position: absolute;
   border: none;
   background-color: #F6F6F6;
   font-size: 1.042vw ;
   z-index: 10;
   left: 71.5vw;
   top: 2.5vh;
   font-weight: 600;
   color: #25395A;
   cursor: pointer;
   animation: blink 1.25s infinite;
 }

 .flBtnRed{
   color: #E73437;
 }





 @keyframes blink {
   0%, 100% {
       opacity: 1;
   }
   50% {
       opacity: 0;
   }
}

.flContainer {
   position: absolute;
   top: 8vh; /* Position below the navbar */
   left: 70%;
   transform: translateX(-50%);
   background-color: #ffffff;
   box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
   border-radius: 5px;
   padding: 10px;
   z-index: 9; /* Ensure it's below the floating button */
   display: flex;
   flex-direction: column;
   gap: 10px;
}

@media (max-width:600px){
    .navbar .logoContainer img{
        width: 16vw;
        margin-left: 2vw;
     }
     .navbar .textContainer .link{
        font-size: 5vw;
     }
     .navbar .menuContainer button{
        right: 8vw;

     }
     .navbar .menuContainer img{
        width: 8vw;
     }

     .flBtn{
      display: none;
     }
    
}


 
