.np-card{
    width: 25vw;
    height: 12.5vw;
    box-Shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10) ;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border: none;
    background-color: white;
}



.np-card-left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.np-card-left-1{
    background-color: #D9D9D9;
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.np-card-left-1 img{
    height: 100%;
    width: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the container */
}

.np-card-left-2{
    height: 25%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.np-card-left-2 h4{
    font-size: 0.833vw;
    font-weight: 600;
    color: #25395A;
}




.np-card-right{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0.417vw;
    padding-right: 0.417vw;
}

.np-card-right-1 {
    width: 100%;
    height: 75%;
    padding-top: 0.417vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: start;
    gap: 0.417vw;
    text-wrap: wrap;
}

.np-card-right-1 h2{
    font-size: 0.833vw;
    font-weight: 700;
}

.np-card-right-1 h4{
    font-size: 0.625vw;
    font-weight: 500;
}

.np-card-right-2{
    width: 100%;
    height: 25%;
    display: flex;
    align-items: start;
    flex-direction: column;
}

.np-card-right-2 h4{
    font-weight: 500;
    font-size: 0.729vw;
}

.np-card-right-2 h3{
    font-weight: 700;
    font-size: 0.938vw;
    margin-top: -0.2vw;
}






@media (max-width:600px){
    .np-card{
        width: 88vw;
        height: 45vw;
    }


    .np-card-left-2 h4{
        font-size: 3.2vw;
    } 



    .np-card-right-1 {
        width: 98%;
        padding-top: 3.0vw;
        gap: 2vw;
        padding-left: 1vw;
        box-sizing: border-box;
        height: 85%;
    }

    .np-card-right-1 h2{
        font-size: 3.4vw;
        font-weight: 700;
    }
    
    .np-card-right-1 h4{
        font-size: 2.5vw;
        font-weight: 500;
    }
      

    .np-card-right-2 {
        padding-left: 1vw;
    }

    .np-card-right-2 h4{
        font-weight: 500;
        font-size: 2.9vw;
    }
    
    .np-card-right-2 h3{
        font-weight: 700;
        font-size: 4vw;
        margin-top: -0.8vw;
    }

    

    
}