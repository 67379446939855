.searchBar{
    width: 62.5vw;
    height: 3.333vw;
    border: 0.15vw solid #E73437;
    background-color: white;
    border-radius: 0.417vw;
    font-family: 'Poppins', sans-serif;
    font-size: 1.25vw;
    font-weight: 300;
    color: #E73437;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.searchBar img{
    right: 1.042vw;
    position: absolute;
    width: 1.25vw;
    height: 1.25vw;
}

@media (max-width:600px){
    .searchBar{
        width: 84.467vw;
        height: 10.667vw;
        border-radius: 1.5vw;
        border: 0.2vw solid #E73437;
        font-size: 3.2vw;
        font-weight: 400;
    }
    .searchBar img{
        right: 3vw;
        position: absolute;
        width: 4.8vw;
        height: 4.8vw;
    }

    
}