.residential{
    display: flex;
    flex-direction: column;
}

.residential .searchBarDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5vw;
}

.residential .titleDiv{
    display: flex;
    justify-content: center; 
    width: 100%;
    height: 3vw;  
}

.residential .titleDiv h1{
    font-size: 2.083vw;
    font-family: 700;
}

.residential .outputDiv{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.ads{
    display: flex;
    justify-content: center;
    padding-top: 1vw;
    padding-bottom: 0.2vw;
}

.extraDiv{
    width: 100%;
    height: 10vw;
}



@media (max-width:600px){
    .residential .searchBarDiv{
        margin-top: 8vw;
    }
    .residential .titleDiv{
        margin-top: 8vw;
    }
    .residential .titleDiv h1{
        font-size: 5.333vw;
        font-family: 700;
    }
    .residential .outputDiv{
        margin-top: 10vw;
    }

    .extraSpace{
        background-color: rgb(255, 255, 255);
        height: 10vw;
        width: 100vw;
    }

    .ads{
        padding-top: 8vw;
        padding-bottom: 0vw;
    }
}