

.privacyContainer{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1.5vw 2vw;
}

.privacyTop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.privacyStandardMiddle{
    margin-top: 2vw;
}


.privacyContainer h1{
    font-size: 1.667vw;
    font-weight: 600;
}
.privacyContainer h2{
    font-size: 1.25vw;
    font-weight: 600;
}
.privacyContainer h3{
    font-size: 1.042vw;
    font-weight: 300;
}


.legalTop{

}
.legalTop h1{
    text-align: center;
}


@media (max-width:600px){
    .privacyContainer{
        padding-top: 4vw;
        padding-left: 4vw;
        padding-right: 4vw;
        padding-bottom: 18vw;
    }
    
    .privacyTop{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5vw;
    }
    
    .privacyStandardMiddle{
        margin-top: 5vw;
    }
    
    
    .privacyContainer h1{
        font-size: 5.2vw;
        font-weight: 600;
    }
    .privacyContainer h2{
        font-size: 4.267vw;
        font-weight: 600;
    }
    .privacyContainer h3{
        font-size: 2.667vw;
        font-weight: 300;
    }
    
}