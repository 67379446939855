

.newProjectForm{
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding-top: 1vw;
    padding-bottom: 2vw;
    padding-right: 2vw;
    padding-left: 2vw;
}

.btnDiv{
    margin-top: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addBtn{
    background-color: #E73437;
    border: none;
    color: white;
    font-size: 1.3vw;
    font-weight: 700;
    width: 12vw;
    height: 4vw;
    border-radius: 0.5vw;
}