

.adContainer{
    display: flex;
    width:  41.667vw;
    max-width: 41.667vw;
    box-sizing: border-box;
    height: auto;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}
.each-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.each-slide img {
    width: 100%;
    height: auto; 
    display: block; 
  }

@media (max-width:600px){
    .adContainer{
        width:  82.133vw;
        max-width: 82.133vw;
    }
}