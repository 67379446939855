.viewmore-body{
    display: flex;
    flex-direction: column;
}



.viewmore-body .topButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1vw;
    box-sizing: border-box;
    padding: 0vw 1.25vw;
    width: 100%;
}

.viewmore-body .topButtons .left{
    height: auto;
    width: auto;
}

.viewmore-body .topButtons .left button{
    background-color: white;
    border: none;
    height: auto;
    width: auto;
}
.viewmore-body .topButtons .left img{
    width: 3.333vw;
    height: 3.333vw;
}

.viewmore-body .topButtons .middle{
    display: none;
}

.viewmore-body .topButtons .right button{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: end;
    border: none;
    background-color: white;
}

.viewmore-body .topButtons .right img{
    height: 3.333vw;
    width: 3.333vw;
}

.viewmore-body .topButtons .right h2{
    font-size: 1.25vw;
    font-weight: 600;
}



.viewmore-body .title h1{
    font-weight: 800;
    font-size: 2.083vw;
    text-align: center;
    width: 100%;
}

.viewmore-body .data{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 2vw 2vw;
}

.viewmore-body .data .left{
    display: flex;
    flex-direction: column;
}
.viewmore-body .data .right{
    display: flex;
    flex-direction: row;
    align-items: start;
    box-sizing: border-box;
}

.viewmore-body .data .right button{
    background-color: white;
    border: none;
}
.viewmore-body .data .right img{
    height: 3vw;
    width: 3vw;
    position: absolute;
    right: 2vw;
}

.viewmore-body .data h2{
    font-size: 1.4vw;
    font-weight: 600;
    padding: 0.25vw 0vw;
}

.viewmore-body .data h3{
    font-size: 1.2vw;
    font-weight: 500;
    padding: 1vw 0vw;
}
.viewmore-body .data button{
    background-color: white;
    border: none;
    color: #25395A;
    font-size: 1.4vw;
    font-weight: 600;
    padding: 1vw 0vw;
}
.capitalize {
    text-transform: capitalize;
}

@media (max-width:600px){
    .viewmore-body .topButtons{
        padding: 5vw 2vw;

    }
    .viewmore-body .topButtons .left img{
        width: 13.867vw;
        height: 13.867vw;
    }
    .viewmore-body .topButtons .middle{
        display: block;
    }
    .viewmore-body .topButtons .middle button{
        background-color: white;
        border: none;
        margin-top: 3vw;
        margin-left: 5vw;
    }
    .viewmore-body .topButtons .middle img{
        width: 10vw;
        height: 10vw;
    }
    .viewmore-body .topButtons .right img{
        height: 13.867vw;
        width: 13.867vw;
    }
    .viewmore-body .topButtons .right h2{
        font-size: 3.2vw;
        font-weight: 600;
    }
    .viewmore-body .title h1{
        font-weight: 800;
        font-size: 6.4vw;
        margin-top: 5vw;
    }
    .viewmore-body .data{
        padding: 5vw 4vw;
    }
    .viewmore-body .data h2{
        font-size: 3.2vw;
        font-weight: 600;
        padding: 1vw 0vw;
    }
    .viewmore-body .data h3{
        font-size: 3vw;
        font-weight: 500;
        padding: 2.5vw 0vw;
    }
    .viewmore-body .data .left button{
        font-size: 3vw;
    }
    .viewmore-body .right .hide{
        display: none;
    }

    .viewmore-body .data .right img{
        display: none;
    }
}