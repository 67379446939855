.bko-container {
    position: relative;
    text-align: center;
    transition: padding-top 0.5s ease-in-out; /* Smooth transition for padding change */
    padding-top: 25vw;
}

.button {
    border: none;
    background-color: white;
    font-family: poppins;
    font-size: 1.667vw;
    font-weight: 700;
    width: 18.75vw;
    height: 4.167vw;
    border-radius: 50vw;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 1; /* Ensure button is on top of images */
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0); /* Center the image and scale it down initially */
    width: 10vw;
    height: 10vw;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.image.show {
    transform: translate(-50%, -50%) scale(1) translateX(35vw); /* Adjust this value to control the distance */
    opacity: 1;
}

.static-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 25vw;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.static-image.show {
    opacity: 1;
}

.static-video{
    border-radius: 1vw; 
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.5s ease-in-out; 
    max-height: 20vw;
}

.static-video.show {
    opacity: 1;
}

.static-text{
    resize: none;
    height: 20vw;
    background-color: white;
    width: 40vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.5s ease-in-out; 
}

.static-text.show {
    opacity: 1;
}


@media (max-width:600px){

    .bko-container {
        padding-bottom: 10vw;
        padding-top: 100vw;
    }
    .static-image {
        width: auto;
        height: 50vw;
        margin-top: 10vw;

    }

    .image {
        width: 15vw;
        height: 15vw;
    }

    .button {
        font-size: 4vw;
        width: 35vw;
        height: 12svw;
        border-radius: 50vw;
    }

    .image.show {
        transform: translate(-50%, -50%) scale(1) translateY(50vw);
    }

    .static-video{
        width: auto;
        top: 80%;
        max-height: 50vw;
        
    }

    .static-text{
    height: 50vw;
    background-color: white;
    width: 80vw;
    top: 75%;
    }
}
