.fullMenu h3 {
    color: #E73437;
    font-weight: 400;
    font-size: 0.833vw;
    text-align: center;
}

.fullMenu{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 47.917vw;
    padding-top: 2.083vw;
}

.fullMenu .item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fullMenu .logo {
    width:4.167vw;
}

.fullMenu .link {
    text-decoration: none;
    color: white;
}

@media (max-width:600px){
    .fullMenu .logo {
        width:10vw;
    }
    .fullMenu{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        gap: 2.5vw;
        width: 71vw;
        padding-top: 5vw;
    }

    .fullMenu h3 {
        color: #E73437;
        font-weight: 400;
        font-size: 2.4vw;
        text-align: center;
    }

    .fullMenu .item{
        margin-bottom: 1vw;
        flex-basis: 23%;
    }

    
}