.npvm{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    padding-bottom: 8vw;
}





.npvm-part1{
    width: 96vw;
    height: 40vw;
    background-color: rgb(189, 189, 189);
    margin-top: 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.npvm-part1 img{
    height: 100%;
    width: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the container */

}







.npvm-part2{
    width: 100%;
}

.npvm-part2 h1{
    font-size: 2.5vw;
    font-weight: 700;
    width: 100%;
    margin-top: 2.083vw;
    text-align: center;
}

.npvm-part2 button{
    background-color: white;
    height: 5vw;
    width: 5vw;
    border-radius: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-Shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3) ;
    float: right;
    cursor: pointer;
    margin-top: -4.5vw;
    margin-right: 2vw;
}

.npvm-part2 img{
    margin-left: -0.4vw;
    height: 3.125vw;
    width: 3.125vw;
}









.npvm-part3{
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-between;
    margin-top: 2.2vw;
    box-sizing: border-box;
    padding-left: 2vw;
    padding-right: 2vw;
}

.npvm-part3 .one{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2.083vw;
    width: 40%;
}

.npvm-part3 .two{
    display: flex;
    flex-direction: column;
    gap: 2.083vw;
    width: 30%;
    flex-wrap: wrap;
}

.npvm-part3 .three{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.npvm-part3 .three h5{
    font-size: 1.25vw;
    font-weight: 600;
    text-align: right;
    margin-right: 1vw;
}
.npvm-part3 .three h4{
    font-size: 1.667vw;
    font-weight: 600;
    text-align: right;
    margin-top: 1vw;
    
}
.npvm-part3 .three h3{
    font-size: 2.083vw;
    font-weight: 700;
    text-align: right;
    
}
.npvm-part3 .three .btnDiv{
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 2vw;
    margin-top: 1vw;
}

.npvm-part3 .three button{
    border: none;
    background-color: white;
    box-Shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3) ;
    height: 4.167vw;
    width: 4.167vw;
    border-radius: 50vw;
    position: relative; /* or absolute */
    z-index: 10; /* Higher z-index to be on top of other elements */

}

.npvm-part3 .three .callBtn img{
    width: 2.917vw;
    height: 2.917vw;
    margin-top: 0.3vw;
    pointer-events: none;
    z-index: 1; /* Lower z-index */
}

.npvm-part3 .three .waBtn img{
    width: 3.8vw;
    height: 3.8vw;
    margin-top: 0.1vw;
    pointer-events: none;
    z-index: 1; /* Lower z-index */
}

.npvmDispText{
    width: auto;
    height: auto;
    box-sizing: border-box;
}
.npvmDispText h3{
    font-size: 1.25vw;
    font-weight: 500;
}
.npvmDispText h4{
    font-size: 1.25vw;
    font-weight: 600;
}
.npvm-part3 .three .nvpm-share-btn{
    display: none;
}








.npvm-part4{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1vw;
}

.npvm-part4 h1{
    font-size: 1.875vw;
    font-weight: 600;
    margin-left: 1.5vw;
}   

.npvm-part4 .text{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Create 3 equal-width columns */
    grid-template-rows: repeat(auto-fit, minmax(1vw, 1fr)); /* Create rows that adjust to fit content */
    row-gap: 2vw;
    column-gap: 17.4vw;
    box-sizing: border-box;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 1vw;
}

.npvm-part4 .table{
    display: block;
}



.npvm-part5{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}
.npvm-part5 h1{
    font-size: 1.875vw;
    font-weight: 600;
    margin-left: 1.5vw;
}

.npvm-part5 .amenities h3{
    font-size: 1.25vw;
    font-weight: 500;
}
.npvm-part5 .amenities{
    box-sizing: border-box;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    padding-left: 2vw;
    justify-content: flex-start;
    padding-top: 2vw;
}

.npvm-part5 .amenities .fixed-amenities{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vw;
    width: auto;
}

.npvm-part5 .amenities .fixed-amenities .column {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
}

.npvm-part5 .amenities textarea{
    resize:none;
    width: 20vw;
    background-color: white;
    border: 0.1vw solid black;
    padding: 0.5vw;
}





.npvm-part6 {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10vw;
  }
  
  .npvm-part6 h1 {
    font-size: 1.875vw;
    font-weight: 600;
    margin-left: 1.5vw;
  }
  
  .npvm-part6 .bkoBtnContainer { 
    display: flex;
    justify-content: center;
    text-align: center;
  }




  











.npvm-part7{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 4vw;
}

.npvm-part7 h1{
    font-size: 1.875vw;
    font-weight: 600;
    margin-left: 1.5vw;
}

.npvm-part7 textarea{
    background-color: white;
    resize: none;
    height: 16vw;
    width: 44vw;
    margin-left: 1.5vw;
    margin-top: 1vw;
    padding: 0.5vw;
    border: 0.1vw solid black;
}



.npvm-part8{
       
    margin-top: 2vw;
    margin: 0;
}

.npvm-part8 textarea{
    background-color: white;
    resize: none;
    height: 16vw;
    width: 44vw;
    margin-left: 1.5vw;
    margin-top: 1vw;
    padding: 0.5vw;
    border: 0.1vw solid black;
}







.npvm-part9{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 4vw; 
}

.npvm-part9 h1{
    font-size: 1.875vw;
    font-weight: 600;
    margin-left: 1.5vw;
}

.npvm-part9 .text{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Create 3 equal-width columns */
    grid-template-rows: repeat(auto-fit, minmax(1vw, 1fr)); /* Create rows that adjust to fit content */
    row-gap: 2vw;
    column-gap: 17.4vw;
    box-sizing: border-box;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 1vw;
}


.npvm-part10{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 4vw; 
}
.npvm-part10 h1{
    font-size: 1.875vw;
    font-weight: 600;
    margin-left: 1.5vw;
}

.npvm-part10 h3{
    font-size: 1.5vw;
    font-weight: 500;
    margin-left: 2.9vw;
    margin-top: 0.8vw;
}


.npvm-part11{
    display: flex;
    flex-direction: column;
    width:100vw;
    max-width: 100vw;
    box-sizing: border-box;
    margin-top: 4vw; 
}

.npvm-part11 h1{
    font-size: 1.875vw;
    font-weight: 600;
    margin-left: 1.5vw;
}

.npvm-part11 .floorPlans{
    margin: auto;
    width: 40%;
    margin-top: 4vw;

}

.each-slide img {
    width: 100%;
    height: auto;
}



.npvm-part12{
    display: flex;
    flex-direction: column;
    width:100vw;
    max-width: 100vw;
    box-sizing: border-box;
    margin-top: 4vw; 
}


.npvm-part12 h1{
    font-size: 1.875vw;
    font-weight: 600;
    margin-left: 1.5vw;
}

.npvm-part12 :nth-child(2){
    width: 80%;
    margin: auto;
}




.extraDiv{
    width: 100%;
}













.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
    border-left: 4px solid #000; /* Black */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }







@media (max-width:600px){

    .npvm{
        margin-bottom: 6vw;
    }

    .npvm-part1{
        margin-top: 4vw;
        width: 90%;
        height: 50vw;
        overflow: hidden;
    }

    .npvm-part1 img{
        max-width: 100%; /* Prevents image from exceeding the container width */
        max-height: 100%; /* Prevents image from growing too tall */
        width: auto;
        height: auto; /* Ensures the aspect ratio is maintained */
        object-fit: cover;
    }



    .npvm-part2{
        width: 96%;
    }
    .npvm-part2 h1{
        font-size: 5vw;
        font-weight: 700;
        margin-top: 4vw;
    }
    .npvm-part2 button{
        display: none;
    }



    .npvmDispText{
        width: auto;
        height: auto;
    }
    .npvmDispText h3{
        font-size: 3.733vw;
        font-weight: 500;
    }
    .npvmDispText h4{
        font-size: 3.733vw;
        font-weight: 600;
    }



    .npvm-part3{
        display: flex;
        flex-direction: column;
        width: 96%;
        margin-top: 5vw;
    }
    .npvm-part3 .one{
        order:2;
        margin-top: 8vw;
        gap: 4vw;
        width: 100%;
    }
    .npvm-part3 .two{
        order:3;
        margin-top: 4vw;
        gap: 4vw;
        width: 100%;
    }
    .npvm-part3 .three{
        order:1;
        justify-content: center;
        width: 100%;
    }
    .npvm-part3 .three .btnDiv{
        order:1;
        justify-content: space-between;
    }
    .npvm-part3 .three h5{
        order:2;
        text-align: center;
        font-size: 4vw;
        margin-top: -8vw;
        margin-right: 0vw;
    }
    .npvm-part3 .three h4{
        order:3;
        font-size: 4vw;
        margin-top: 8vw;
    }
    .npvm-part3 .three h3{
        order:4;
        font-size: 6vw;
        margin-top: -1.5vw;
    }

    .npvm-part3 .three button{  
        height: 12vw;
        width: 12vw;
    }
    
    .npvm-part3 .three .callBtn img{
        width: 8vw;
        height: 8vw;
        margin-top: 1.4vw;
    }
    
    .npvm-part3 .three .waBtn img{
        width: 10vw;
        height: 10vw;
        margin-top: 1vw;
    }

    .npvm-part3 .three .nvpm-share-btn{
        display: block;
        order: 4;
        margin-top: -13vw;
    }
    .npvm-part3 .three .nvpm-share-btn .shareBtnImage{
        height: 8vw;
        width: 8vw;
        margin-top: 1.5vw;
        margin-left: -1vw;
    }



    .npvm-part4{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 8vw;
    }
    
    .npvm-part4 h1{
        font-size: 6.4vw;
        font-weight: 600;
        margin-left: 2vw;
        
    }
    
    .npvm-part4 .text{
        display: flex;
        flex-direction: column;
        padding-left: 4vw;
        gap: 4vw;
    }

    .npvm-part4 .table{
        margin-top: 3vw;
    }







    .npvm-part5 h1{
        font-size: 6.4vw;
        margin-left: 2vw;
    }
    
    .npvm-part5 .amenities h3{
        font-size: 4vw;

    }
    .npvm-part5 .amenities{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .npvm-part5 .amenities .fixed-amenities{
        display: flex;
        flex-direction: column;
        gap: 2vw;
        border: 0.2vw solid black;
        padding: 2vw 4vw;
    }
    
    .npvm-part5 .amenities .fixed-amenities .column {
        gap: 1vw;
    }
    
    .npvm-part5 .amenities textarea{
        width: 50vw;
        border: 0.2vw solid black;
        padding: 1vw;
        margin-top: 5vw;
    }


      .npvm-part6 h1 {
        font-size: 6.4vw;
        margin-left: 2vw;
      }
      


      .npvm-part7{
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        margin-top: 4vw;
        padding-top: 40vw;
    }
    
    .npvm-part7 :nth-child(2){
        display: flex;
        justify-content: center;
        margin-top: 5vw;
    }

    .npvm-part7 h1{
        font-size: 6.4vw;
        margin-left: 2vw;
    }
    
    .npvm-part7 textarea{ 
        height: 80vw;
        width: 80vw;
        margin-left: 0vw;
        padding: 2vw;
    }


    .npvm-part8{
       
        margin-top: 8vw;
    }
    
    .npvm-part8 textarea{
        height: 80vw;
        width: 80vw;
        margin-left: 0vw;
        margin-top: 4vw;
        padding: 2vw;
        background-color: white;
    }








    .npvm-part9{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 8vw;
    }

    .npvm-part9 h1{
        font-size: 6.4vw;
        font-weight: 600;
        margin-left: 2vw;
        
    }
    .npvm-part9 .text{
        display: flex;
        flex-direction: column;
        padding-left: 4vw;
        gap: 4vw;
    }




    .npvm-part10 h1{
        font-size: 6.4vw;
        margin-left: 2vw;
    }
    
    .npvm-part10 h3{
        font-size: 5vw;
        margin-left: 7vw;
    }

  
    .npvm-part11{
        margin-top: 10vw;
    }
    .npvm-part11 h1{
        font-size: 6.4vw;
        margin-left: 2vw;
    }
    
    .npvm-part11 .floorPlans{
        width: 80%;
        margin-top: 4vw;
        margin-top: 5vw;
    
    }

    .npvm-part12{
        
        width:100vw;
        max-width: 100vw;
        box-sizing: border-box;
        margin-top: 10vw; 
    }
    
    
    .npvm-part12 h1{
        font-size: 6.4vw;
        margin-left: 2vw;
    }
    
    .npvm-part12 :nth-child(2){
        width: 80%;
        margin: auto;
        margin-top: 5vw;
    }


    .extraDiv{
        height: 20vw;;
    }
}