

.flMenu{
    background-color: white;
    height: 9.896vw;
    width: 12.5vw;
    position: absolute;
    top: 4vw;
    left: 70.5vw;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.833vw;
    box-sizing: border-box;
    border-radius: 0.781vw;
    box-Shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) ;
}

.flLink{
    text-decoration: none;
    color: black;
    font-size: 0.938vw;
    font-weight: 500;
}

@media (max-width:600px){
    .flMenu{
        position: relative;
        height: 35vw;
        width: 35vw;
        top: -72vw;
        left: 60vw;
        border-radius: 4.267vw;
        gap: 3vw;
    }

    .flLink{
        font-size: 3.2vw;
    }
}