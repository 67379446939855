.tableDiv{
    width: 100%;
    display: flex;
    justify-content: center;
}


table{
    border-collapse: collapse;
    width: auto;
}

th, td {
    border: 0.12vw solid rgb(19, 19, 19);
    text-align: center;
    padding: 1vw 3vw;
}

thead{
    font-size: 1.042vw; 
    font-weight: 700;
}

tbody{
    font-size: 1.042vw; 
    font-weight: 500;
}






@media (max-width:600px){

    th, td {
        border: 0.25vw solid rgb(19, 19, 19);
        text-align: center;
        padding: 1.5vw 3vw;
    }
    
    thead{
        font-size: 2.8vw; 
        font-weight: 700;
    }
    
    tbody{
        font-size: 2.8vw; 
        font-weight: 500;
    }
    
}