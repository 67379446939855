
.addContainer{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
padding-bottom: 5vw;
padding-top: 2vw;
}

.addContainer .top{
    text-align: center;
}
.addContainer .top h1{
    font-size: 2.083vw;
    font-weight: 700;
}

.addContainer .bottom{
    display: flex;
    flex-direction: row;
    padding-top: 2vw;
}

.addContainer .bottom .left{
    width: 60%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addContainer .bottom .leftData{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.25vw;
}



.addContainer .title{
    width: 48vw;
}

.addContainer .contact{
    width: 20vw;
}




.addContainer .left .dropdown-list{
    display: flex;
    width: 48vw;
    flex-direction: row;
    gap: 3vw;
    justify-content: flex-start;
}

.addContainer .left .dropdown-list h4{
    font-size: 1.042vw;
    font-weight: 600;
}

.addContainer .left .dropdown-list select{
    height: 2.5vw;
    border-radius: 0.28vw;
    border: 0.1vw solid #777777;
    font-weight: 400;
    font-size: 0.9vw;
    outline: none;
    padding-left: 0.5vw;
    padding-right: 1vw;
    background-color: white;
}

.addContainer .left .dropdown-list .ddStandard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}







.addContainer .right{
    width: 40%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
} 

.addContainer .right button{
    background-color: #E73437;
    border: none;
    color: white;
    font-size: 1.3vw;
    font-weight: 700;
    width: 12vw;
    height: 4vw;
    border-radius: 0.3vw;
}


.addContainer .standard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.addContainer .standard h4{
    font-size: 1.042vw;
    font-weight: 600;
}

.addContainer .standard input[type="text"]{
    height: 2.5vw;
    border-radius: 0.28vw;
    border: 0.1vw solid #777777;
    font-weight: 400;
    font-size: 0.9vw;
    outline: none;
    padding-left: 1vw;
    padding-right: 1vw;
}

.addContainer .standard input[type="number"]{
    height: 2.5vw;
    border-radius: 0.28vw;
    border: 0.1vw solid #777777;
    font-weight: 400;
    font-size: 0.9vw;
    outline: none;
    padding-left: 1vw;
    padding-right: 1vw;
}

.addContainer textarea{
   width: 48vw;
   resize: none;
   height: 15vw;
   padding: 1vw;
   font-weight: 400;
   font-size: 0.9vw;
   box-sizing: border-box;
   outline: none;
   border-radius: 0.3vw;
   border: 0.1vw solid #777777;
}

.error {
    color: red;
    font-size: 0.8vw;
    font-weight: 600;
    display: block;
    margin-top: 0.5vw;
}


.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
    border-left: 4px solid #000; /* Black */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


/* Hides Up and Down Arrow in Input type=number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}


@media (max-width:600px){

    .addContainer{
        padding-top: 6vw;
        padding-bottom: 20vw;
    }
    .addContainer .top h1{
        font-size: 4.8vw;
        font-weight: 700;
    }

    .addContainer .bottom{
        flex-direction: column;
    }

    .addContainer .bottom .left{
        width: 100%;
    }
    .addContainer .bottom .leftData{
        gap: 4vw;
    }

    .addContainer .standard h4{
        font-size: 3.6vw;
    }

    .addContainer .title{
        width: 90vw;
    }
    .addContainer .contact{
        width: 50vw;
    }

    .addContainer .left .dropdown-list{
        flex-direction: column;
        gap: 4vw;
    }

    .addContainer .left .dropdown-list h4{
        font-size: 3.6vw;
    }

    .addContainer .left .dropdown-list select{
        height: 8vw;
        border-radius: 0.7vw;
        font-size: 3.6vw;
        padding-left: 1vw;
        padding-right: 2vw;
        border: 0.1vw solid #777777;
    }


    .addContainer .right{
        width: 100%;
        margin-top: 7vw;
        
    } 

    .addContainer .right button{
        font-size: 3.6vw;
        width: 40vw;
        height: 12vw;
        border-radius: 1vw;
    }




    .addContainer .standard input[type="text"]{
        height: 8vw;
        border-radius: 0.6vw;
        font-size: 3.2vw;
        padding-left: 2vw;
        padding-right: 2vw;
    }

    .addContainer .standard input[type="number"]{
        height: 8vw;
        border-radius: 0.6vw;
        font-size: 3.2vw;
        padding-left: 2vw;
        padding-right: 2vw;
    }
    .addContainer textarea{
        width: 90vw;
        height: 60vw;
        padding: 1vw;
        font-weight: 400;
        font-size: 3.2vw;
        box-sizing: border-box;
        outline: none;
        border-radius: 0.7vw;
     }

     .error {
        
        font-size: 3vw;
        margin-top: 1vw;
    }
}