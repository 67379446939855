.newProjects{
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.newProjects .searchBarDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5vw;
}

.newProjects .titleDiv{
    display: flex;
    justify-content: center; 
    width: 100%;
    height: 3vw;  
}

.newProjects .titleDiv h1{
    font-size: 2.083vw;
    font-family: 700;
}

.newProjects .outputDiv{
    width: 100%;
    height: auto;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-left: 5vw;
    padding-right: 5vw;
}


.newProjects .extraSpace{
    background-color: rgb(255, 255, 255);
    height: 2vw;
    width: 100vw;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
    border-left: 4px solid #000; /* Black */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media (max-width:600px){
    .newProjects .searchBarDiv{
        margin-top: 8vw;
    }
    .newProjects .titleDiv{
        margin-top: 8vw;
    }
    .newProjects .titleDiv h1{
        font-size: 5.333vw;
        font-family: 700;
    }
    .newProjects .outputDiv{
        gap: 5vw;
        padding-top: 10vw;
        padding-bottom: 10vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .extraSpace{
        background-color: rgb(255, 255, 255);
        height: 20vw;
        width: 100vw;
    }
}