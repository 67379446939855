.logistics{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.logistics .top {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 10vh;
    align-items: center;
}
.logistics .top .text{
    text-align: center;
    position: absolute;
    width : 100vw;
}

.logistics .top h1{
    color:black;
    font-size: 2.083vw;
    font-weight: 700;
}

.logistics .top .whatsapp{
    position: absolute;
    right : 0.833vw;
}

.logistics .services {
    display: flex;
    flex-direction: column;
    width: 23.75vw;
    margin-left: 11vw;
}

.logistics .services h2 {
    font-size: 1.458vw;
    font-weight: 600;
    margin-top: 1vw;
}

.extraDiv{
    width: 100%;
    height: 10vw;
}

@media (min-width: 601px){
    .servicewithWA{
        display: none;
    }
    .homeMenu{
        display: none;
    }
}

@media (max-width:600px){

    .logistics .whatsapp{
        display: none;
    }

    .logistics .homeMenu{
        display: block;
    }

    .logistics .top .text h1{
        font-size: 5.333vw;
    }
    .logistics .services {
        display: none;
    }

    .WAService{
        width: 90vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2vw;
    }

    .WAService img{
        height: 8vw;
    }
    .WAService h2{
        font-size: 4.267vw;
        font-weight: 600;
    }
    .WAService button{
        height: auto;
        width: auto;
        background-color: white;
        border: none;
    }


}
