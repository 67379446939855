@media only screen and (min-width:601px){
    .btnMenu{
        position: absolute;
        right: 1vw;
        z-index: 999;
    }

    .dispMenu {
        width: 15vw;
        height: 16vw;
        display: flex;
        flex-direction: column;
        gap: 0.833vw;
        margin-top: 0.8vw;
        box-sizing: border-box;
        background-color: #F6F6F6;
        align-items: end;
        justify-content: center;
        padding-right: 0.5vw;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    }

    .btnMenu .dispMenu .link {
        text-decoration: none;
        border: none;
        color: black;
        font-size: 0.833vw;
        font-weight: 500;
        background-color: #F6F6F6;
        font-family: 'Poppins', sans-serif;
    }
}

@media (max-width:600px){
    .dispMenu{
        display: block;
        height: 65vw;
        width: 100%;
        background-color: #E73437;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .btnMenu .dispMenu .link{
        color: white;
        text-decoration: none;
        font-size: 4.267vw;
        font-weight: 500;
        text-align: center;
        margin-top: 2.133vw;
    }
}