
.npFilter-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    padding-left: 10vw;
    padding-right: 10vw;
    overflow: scroll;
}

.npFilter-container h1{
    font-size: 1.875vw;
    font-weight: 600;
    text-align: center;
    padding-top: 1vw;
    padding-bottom: 0.8vw;
}

.npLocationInput{
    display: flex;
    justify-content: center;
    padding-top: 0.5vw;
}
.npLocationInput input{
    background-color: white;
    border: 0.15vw solid #E73437;
    border-radius: 0.4vw;
    width: 80vw;
    height: 3.333vw;
    color: rgb(0, 0, 0);
    font-size: 1.25vw;
    font-weight: 400;
    box-sizing: border-box;
    padding: 1vw 1vw;
    font-family:'Poppins', sans-serif;
    outline: none;
}

.npLocationInput input::placeholder {
    color: #E73437;
    text-align: center;
}

.npCategory{
    display: flex;
    justify-content: flex-start;
    gap: 2vw;
    margin-top: 2vw;
}
.npCategory h3{
    font-size: 1.25vw;
    font-weight: 500;
}


.npResidential{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2vw;
    margin-top: 3vw;
}


.npResidential :first-child{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
}

.npResidential :nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
}

.npResidential h3{
    font-size: 1.25vw;
    font-weight: 500;
}

.npResidential select{
    height: 2.5vw;
    border-radius: 0.28vw;
    border: 0.1vw solid #777777;
    font-weight: 400;
    font-size: 1.2vw;
    outline: none;
    padding-left: 0.5vw;
    padding-right: 1vw;
    background-color: white;
    width: 18vw;
}

.npPrice{
    display: flex;
    flex-direction: column;
}

.npPrice h3{
    font-size: 1.25vw;
    font-weight: 500;
}

.npPrice .minP{
    display: flex;
    flex-direction: row;
    margin-top: 2vw;
    gap: 2vw;
    align-items: center;
}


.npPrice .minP input{
    background-color: white;
    border: 0.1vw solid black;
    border-radius: 0.4vw;
    height: 2.5vw;
    width: 15vw;
    padding-left: 1vw;
    padding-right: 1vw;
    font-family:'Poppins', sans-serif;
    outline: none;
    font-size: 1vw;
    font-weight: 400;
}

.npPrice select{
    height: 2.5vw;
    border-radius: 0.28vw;
    border: 0.1vw solid #777777;
    font-weight: 400;
    font-size: 1.2vw;
    outline: none;
    padding-left: 0.5vw;
    padding-right: 1vw;
    background-color: white;
    width: 7vw;
    margin-left: 2vw;
}

.npArea{
    display: flex;
    flex-direction: row;
    gap: 4vw;
    padding-top: 2vw;
}

.npArea .minArea{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2vw;
}

.npArea .minArea h3{
    font-size: 1.25vw;
    font-weight: 500;
}

.npArea  input{
    background-color: white;
    border: 0.1vw solid black;
    border-radius: 0.4vw;
    height: 2.5vw;
    width: 15vw;
    padding-left: 1vw;
    padding-right: 1vw;
    font-family:'Poppins', sans-serif;
    outline: none;
    font-size: 1vw;
    font-weight: 400;
}



.npBOBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2vw;
}

.npBOBtn .toggle-button {
    background-color: white;
    color: #E73437;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 0.15vw solid #E73437;
    font-size: 1vw;
    font-weight: 500;
    outline: none;
  }
  
.npBOBtn .toggle-button.selected {
    background-color: #E73437;
    color: white;
}

.npFilter-container .npBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.npBtn button{
    background-color: #E73437;
    color: white;
    width: 7.969vw;
    height: 2.708vw;
    border: none;
    border-radius: 0.3vw;
    font-size: 1.15vw;
    font-weight: 600;
    font-family:'Poppins', sans-serif;
}


@media (max-width:600px){

    .npFilter-container{
        padding-left: 5vw;
        padding-right: 5vw;
        overflow: scroll;
    }

    .npFilter-container h1{
        font-size: 5.333vw;
        padding-top: 5vw;
        padding-bottom: 4vw;
    }

    .npLocationInput input{
        border: 0.3vw solid #E73437;
        border-radius: 1.3vw;
        width: 90vw;
        height: 10.667vw;
        font-size: 3.5vw;
        padding: 1vw 1vw;

    }

    .npCategory{
        gap: 3vw;
        padding-top: 6vw;
        align-items: center;
    }

    .npCategory h3{
        font-size: 3.733vw;
    }



    
    .npResidential{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7vw;
        padding-top: 5vw;
    }

    .npResidential :nth-child(1){
        gap: 4vw;
    }
    .npResidential :nth-child(2){
        gap: 5vw;
    }
    
    .npResidential h3{
        font-size: 3.733vw;
    }
    
    .npResidential select{
        height: 10.667vw;
        border-radius: 1vw;
        font-size: 3.5vw;
        padding-left: 2vw;
        padding-right: 0vw;
        width: 48vw;
    }




    .npPrice{
        display: flex;
        flex-direction: column;
    }
    
    .npPrice h3{
        font-size: 3.733vw;
    }
    
    .npPrice .minP{
        margin-top: 8vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .npPrice .minP :nth-child(2){
        display: flex;
        flex-direction: row;
        gap: 5vw;
    }
    
    .npPrice .minP input{
        border-radius: 1vw;
        height: 10.667vw;
        width: 50vw;
        padding-left: 2vw;
        padding-right: 2vw;
        font-size: 3.5vw;
    }
    
    .npPrice select{
        height: 10.667vw;
        border-radius: 1vw;
        font-weight: 400;
        font-size: 3.5vw;
        width: 20vw;
        margin-left: 0vw;
    }



    .npArea{
        display: flex;
        flex-direction: column;
        padding-top: 7vw;
    }
    
    .npArea .minArea{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .npArea .minArea h3{
        font-size: 3.733vw;
    }
    
    .npArea  input{
        border-radius: 1vw;
        height: 10.667vw;
        width: 50vw;
        padding-left: 2vw;
        padding-right: 2vw;
        font-size: 3.5vw;
    }




    .npBOBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7vw;
    }
    
    .npBOBtn .toggle-button {
        border-radius: 1vw;
        border: 0.2vw solid #E73437;
        width: 40vw;
        height: 10vw;
        font-size: 3.5vw;
    }
    

    .npFilter-container .npBtn{
        padding-top: 7vw;
        padding-bottom: 15vw;

    }
    
    .npBtn button{
        width: 40vw;
        height: 10vw;
        border-radius: 1vw;
        font-size: 3.733vw;
    }
    

}


