body{
    width: 100vw;
}

.homepage {
    display: flex;
    flex-direction: column;
}

.homepage .homePageMenu {
    display: flex;
    justify-content: center;
}

.homepage .homePageText {
    margin-left: 5.208vw;
}

.homepage .homePageText h1 {
    font-size: 5vw;
    color: #25395A;
    font-weight: 800;
}

.homepage .homePageText h2 {
    font-size: 1.667w;
    color: black;
    font-weight: 400;
}

.homepage .homePageText h3 {
    margin-top: 1.979vw;
    font-size: 0.8vw;
    color: black;
    font-weight: 300;
    width: 41.667vw;
    text-align: justify;
}

.flAddBtn{
    display: none;
}
.flAddBtn img{
        display: none;
}

@media (max-width:600px){
    .homepage .homePageText h1 {
        font-size: 9.6vw;
    }
    .homepage .homePageText h2 {
        font-size: 3.733vw;
    }
    .homepage .homePageText h3 {
        margin-top: 1.979vw;
        font-size: 2.667vw;
        width: 85.333vw;
        text-align: justify;
    }
    .homepage .homePageText {
        display: flex;
        flex-direction: column;
        margin-top: 15vw;

    }
    .flAddBtn{
      position: relative;
      display: block;
      top: -36vh;
      left: 73vw;
      height: 22vw;
      width: 22vw;
      background-color: white;
      border: none;
      border-radius: 50%;
      box-Shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10) ;
      outline: none;
      font-size: 2.4vw;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .flAddBtn img{
        display: block;
        width: 8.533vw;
        height: 8.533vw;
        margin-bottom: 1vw;
    }
}